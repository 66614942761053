import React, { Fragment, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { FaAngleDown, FaAngleRight } from "react-icons/fa"
import { useLocation } from "@reach/router"

import {
  ProductTitleWrapper,
  ProductSingleTitle,
  ProductInfoSection,
  Container,
  ProductTopDesktopSection,
  ProductInfoBox,
  ProductImageSection,
  ProductOverlay,
  ProductRightInfoSection,
  ProductSummaryTop,
  ProductSummaryBottom,
  ProductBottomInfo,
  DesktopProductBottomPriceInfo,
  MobileProductBottomPriceInfo,
  ProductBottomCartButtonBox,
  ProductInfoBottom,
  DesktopPrice,
  ProductAlmaPriceWrapper,
  ProductAlmaPrice,
  ProductPriceWrapper,
  ProductPrice,
  ProductPlusDetail,
  GuideSizeDetail,
  ProductAlmaDetail,
  ProductInfoBottomRight,
  ProductAddToCart,
  Select,
  LayerDisabled,
  UserTypeWrapper,
  ChooseGenderText,
  ErrorBox,
  DropIcon,
  DropIconMobile,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  ChooseSizeSeggestionText,
  ProductStrikePrice,
  ProductDiscountPrice,
  LyneupContainer,
  SizeRulerBox,
  SizeRulerImage,
} from "../../../components/Products/styled"

import ProductTopSection from "../../../components/Products/ProductTopSection"
import ProductImageSlider from "../../../components/Products/ProductImageSlider"
import ProductMaterialInfo from "../../../components/Products/ProductMaterialInfo"
import ProductUserType from "../../../components/Products/ProductUserType"
import ProductColorBox from "../../../components/Products/ProductColorBox"
import ProductQuantity from "../../../components/Products/ProductQuantity"
import ProductInfoBanner from "../../../components/Products/ProductInfoBanner"
import ProductResultSection from "../../../components/Products/ProductResultSection"
import ProductWhyChooseSection from "../../../components/Products/ProductWhyChooseSection"
import ProductExpertSection from "../../../components/Products/ProductExpertSection"
import ProductSatisfiedClientSection from "../../../components/Products/ProductSatisfiedClientSection"
import ProductVideoSection from "../../../components/Products/ProductVideoSection"
import ProductReviewSection from "../../../components/Products/ProductReviewSection"
import ProductFaqSection from "../../../components/Products/ProductFaqSection"
import CustomerService from "../../../components/Layout/CustomerService"
import Footer from "../../../components/Layout/Footer"
import Testimonials from "../../../components/Layout/Testimonials"
import { withStoreContext } from "../../../context/StoreContext"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import PriceDetails from "../../../components/Products/PriceDetails"
import SizeGuidePopup from "../../../components/Products/SizeGuidePopup"
import {
  CapitalizeFirstLetter,
  colorsLyneFit,
  imagesLyneFit,
  gendersLyneFit,
  imageSliderLyneFit,
  colorsReverseLyneFit,
  genderReverseLyneFit,
} from "../../../utils/lyneFitHelper"
import BottomStickyAddToCart from "../../../components/Products/BottomStickyAddToCart"
import SeoComp from "../../../components/SeoComp"
import {
  addToCartScript,
  productGtmEvent,
} from "../../../utils/additionalScriptHelpers"
import MedicaleInfoSection from "../../../components/Layout/MedicaleInfoSection"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import ProductBlueBanner from "../../../components/Products/ProductBlueBanner"
import { monthlyPrice, navigateToCart } from "../../../utils/navigateToCart"
import ProductKlarnaSection from "../../../components/Products/ProductKlarnaSection"
import ProductPriceInformationSection from "../../../components/Products/ProductPriceInformationSection"
import TestimonialSection from "../../../components/Layout/TestimonialSection"
import LyneupBProductTopSection from "../../../components/Products/LyneupBProductTopSection"
import TrustPilotReviewSection from "../../../components/Products/TrustPilotReviewSection"

const LyneFit = ({
  intl,
  data,
  data: { shopifyProduct: product },
  storeContext,
  storeContext: { client, checkout, addVariantToCart },
}) => {
  let defaultOptionValues = {}
  let titleBorderColor = "lynefit"
  product.options.forEach(selector => {
    defaultOptionValues[selector.name] = selector.values[0]
  })
  const [selectedVariant, setselectedVariant] = useState(null)
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectOptions, setselectOptions] = useState({})
  const [openModal, setopenModal] = useState(false)
  const [openAlmaModal, setopenAlmaModal] = useState(false)
  const [openSizeGuideModal, setopenSizeGuideModal] = useState(false)
  const [Errors, setErrors] = useState({ exist: false, message: [] })
  const [activeFlag, setactiveFlag] = useState(false)
  const [IsMobile, setIsMobile] = useState(false)
  const [discountPrice, setdiscountPrice] = useState(
    process.env.GATSBY_CURRENCY_TYPE === "gbp"
      ? 84
      : process.env.GATSBY_CURRENCY_TYPE === "eur"
      ? 99
      : ""
  )

  const handleQuantityChange = event => {
    if (event.target.value > 1) {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 74
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 89
          : ""
      )
    } else {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 84
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 99
          : ""
      )
    }
    setselectedVariantQuantity(event.target.value)
    productGtmEvent(product, selectedVariant, event.target.value, intl.locale)
  }

  const handleParamStrings = selectedOptions => {
    let paramString = ""
    let currUrl = isBrowser && window.location.pathname
    Object.keys(selectedOptions).map(item => {
      if (paramString.length === 0) {
        if (item === "Gender") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        }
      } else {
        if (item === "Gender") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        }
      }
    })

    // navigate(`${currUrl}${paramString}`)
    isBrowser && history.replaceState(null, null, paramString)
  }

  const removeErros = option => {
    let error = { ...Errors }
    if (error.exist) {
      // if geneder exists
      if (option["Gender"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "gender_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if color exists
      if (option["Color"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "color_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if size exists
      if (option["Size"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "size_not_seleceted_error_lynefit",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      if (error.message.length === 0) error.exist = false
      setErrors(error)
    }
  }

  const handleChangeSelectOptions = event => {
    const { target } = event
    let selectedOptions = { ...selectOptions }

    if (target.name === "Color") {
      selectedOptions[target.name] = colorsLyneFit[intl.locale][target.value]
    } else if (target.name === "Gender") {
      selectedOptions[target.name] = gendersLyneFit[intl.locale][target.value]
      if (!selectOptions["Color"]) {
        // selectedOptions["Color"] = "BLUE"
        selectedOptions["Color"] = "BLACK MICRO-PERFORATED"
      }
    } else {
      if (target.value) selectedOptions[target.name] = target.value
    }
    removeErros(selectedOptions)
    handleParamStrings(selectedOptions)
    setselectOptions(selectedOptions)

    if (Object.keys(selectedOptions).length === 3) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        selectedOptions
      )
      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
      setselectedVariant(selectedVariant)
    }
    // setselectedVariantImage(
    //   images[selectedOptions["Gender"]][selectedOptions["Color"]]
    // )
  }

  const handleErrors = () => {
    let error = { ...Errors }
    if (!selectOptions["Gender"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "gender_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "gender_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Color"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "color_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "color_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Size"] || selectOptions["Size"].length > 3) {
      error.exist = true
      let found = error.message.find(
        element =>
          element ===
          intl.formatMessage({ id: "size_not_seleceted_error_lynefit" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "size_not_seleceted_error_lynefit" }),
        ]
    }

    removeErros(selectOptions)
    setErrors(error)
  }

  const handleAddTocart = () => {
    if (selectedVariant) {
      addVariantToCart(
        selectedVariant.id,
        selectedVariantQuantity,
        null,
        checkout => {
          addToCartScript(
            product.id,
            product.title,
            selectedVariant,
            selectedVariantQuantity,
            checkout,
            intl.locale
          )
          navigateToCart(intl.locale)
        }
      )
    } else {
      handleErrors()
    }
  }

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const genderParam = urlParams.get("Gender")
    const colorParam = urlParams.get("Color")
    const sizeParam = urlParams.get("Size")
    let options = { ...selectOptions }
    if (genderParam) {
      options.Gender = genderParam
      // options.Color = "BLUE"
      options.Color = "BLACK MICRO-PERFORATED"
    }

    if (colorParam) {
      options.Color = colorParam
    }
    //   else {
    //     options.Color = "BLACK MICRO-PERFORATED"
    //   }

    if (sizeParam) {
      options.Size = sizeParam
    }

    if (Object.keys(options).length === 3) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        options
      )
      setselectedVariant(selectedVariant)
    }

    setselectOptions(options)
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  const handleScrolling = () => {
    $("#sticy-button").click(function() {
      var w = window.outerWidth
      if (w <= 600) {
        $("html, body").animate(
          {
            scrollTop: $("#mobile_below_image").offset().top - 150,
          },
          1500
        )
      } else {
        $("html, body").animate(
          {
            scrollTop: $("body").offset().top,
          },
          1500
        )
      }
      handleErrors()
    })
  }

  const getDiscountPrice = event => {
    if (selectedVariantQuantity > 1) {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 74
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 89
          : ""
      )
    } else {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 84
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 99
          : ""
      )
    }
  }

  const windowResize = () => {
    var w = window.outerWidth
    if (w <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    $(window).resize(() => {
      var w = window.outerWidth
      if (w <= 600) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  var searchLocation = useLocation()
  useEffect(() => {
    BottomBarActive()
    productGtmEvent(
      product,
      selectedVariant,
      selectedVariantQuantity,
      intl.locale
    )
  }, [])

  useEffect(() => {
    getDiscountPrice()
  }, [selectedVariantQuantity])

  useEffect(() => {
    handleScrolling()
    windowResize()
  }, [selectOptions])

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  return (
    data &&
    data.allDirectusLynefitTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="lynefit"
          hreflangPathname={{
            fr: `shop/lynefit`,
            en: `shop/lynefit`,
            de: `shop/lynefit`,
            es: `shop/lynefit`,
          }}
          noindex={true}
          nofollow={true}
        />

        <BottomStickyAddToCart
          title={data.allDirectusLynefitTranslation.nodes[0].product_title}
          image={
            data.allDirectusLynefitTranslation.nodes[0].product_title_logo_image
          }
          gender={genderReverseLyneFit[intl.locale][selectOptions["Gender"]]}
          cart_text={
            !selectOptions["Gender"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_gender",
                })
              : !selectOptions["Size"]
              ? intl.formatMessage({ id: "button_text_sticky_banner_no_size" })
              : data.allDirectusLynefitTranslation.nodes[0]
                  .product_add_to_cart_button
          }
          regularPrice={Number(
            selectedVariant
              ? selectedVariant.price
              : product.priceRangeV2.maxVariantPrice.amount
          ).toFixed(0)}
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          active={activeFlag}
          page="lyneup"
          // discountPrice={discountPrice}
          id="sticy-button"
        />

        <PriceDetails
          details={data.allDirectusLynefitTranslation.nodes[0].price_details}
          open={openModal}
          closeHandle={setopenModal}
        />
        <PriceDetails
          details={data.allDirectusLynefitTranslation.nodes[0].alma_popup_text}
          open={openAlmaModal}
          closeHandle={setopenAlmaModal}
        />
        <SizeGuidePopup
          sizeChart={data.allDirectusLynefitTranslation.nodes[0]}
          gender={selectOptions["Gender"]}
          page="lynefit"
          sizeText={data.allDirectusLynefitTranslation.nodes[0].size_chart_text}
          open={openSizeGuideModal}
          closeHandle={setopenSizeGuideModal}
        />

        <LyneupContainer>
          <ProductInfoBox>
            <ProductImageSection page="lyneup">
              {!(selectOptions["Gender"] && selectOptions["Color"]) && (
                <ProductImageSlider
                  data={imageSliderLyneFit[intl.locale]["Default"]}
                  medicaleLogo={
                    data.allDirectusCommonTranslation.nodes[0].medicale_logo &&
                    data.allDirectusCommonTranslation.nodes[0].medicale_logo
                      .data.url
                      ? data.allDirectusCommonTranslation.nodes[0].medicale_logo
                          .data.url
                      : "https://static.percko.com/uploads/680a855d-5e53-4258-9bdd-20d332b38c16.png"
                  }
                  page="lyneup"
                  video_text={data.allDirectusNewHomePageTranslation.nodes[0]}
                  video="https://player.vimeo.com/video/731432043?h=43caddc329&title=0&byline=0&portrait=0&playsinline=0&autopause=0"
                />
              )}

              {selectOptions["Gender"] && selectOptions["Color"] && (
                <ProductImageSlider
                  data={
                    selectOptions["Gender"] && selectOptions["Color"]
                      ? imageSliderLyneFit[intl.locale][
                          selectOptions["Gender"]
                        ][selectOptions[["Color"]]]
                      : data.allDirectusLynefitTranslation.nodes[0]
                          .product_images_section.men.noir
                  }
                  medicaleLogo={
                    data.allDirectusCommonTranslation.nodes[0].medicale_logo &&
                    data.allDirectusCommonTranslation.nodes[0].medicale_logo
                      .data.url
                      ? data.allDirectusCommonTranslation.nodes[0].medicale_logo
                          .data.url
                      : "https://static.percko.com/uploads/680a855d-5e53-4258-9bdd-20d332b38c16.png"
                  }
                  page="lyneup"
                />
              )}
            </ProductImageSection>

            <ProductRightInfoSection page="lyneup">
              <LyneupBProductTopSection
                image={
                  data.allDirectusLynefitTranslation.nodes[0]
                    .product_title_logo_image.data.url
                }
                price={Number(
                  selectedVariant
                    ? selectedVariant.price
                    : product.priceRangeV2.maxVariantPrice.amount
                ).toFixed(0)}
                title={
                  data.allDirectusLynefitTranslation.nodes[0].product_title
                }
                intl={intl}
                openAlma={setopenAlmaModal}
                isMobile={IsMobile}
                page="lyneup_landing"
              />

              <div
                style={{
                  background: IsMobile ? "#e8e8e8" : "transparent",
                  padding: IsMobile ? "15px 20px 25px" : "0px",
                  marginTop: IsMobile ? "-10px" : "0px",
                }}
              >
                <UserTypeWrapper page="lyneup_2b">
                  {data.allDirectusLynefitTranslation.nodes[0]
                    .choose_gender_text && (
                    <ChooseGenderText
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLynefitTranslation.nodes[0]
                            .choose_gender_text,
                      }}
                    />
                  )}
                  <ProductUserType
                    data={
                      data.allDirectusLynefitTranslation.nodes[0].gender.item
                    }
                    handleChange={handleChangeSelectOptions}
                    value={selectOptions["Gender"]}
                    error={selectOptions["Gender"] ? null : Errors.exist}
                    product={"Lyne FIT"}
                    page="lyneup"
                  />
                </UserTypeWrapper>

                <ProductColorBox
                  data={data.allDirectusLynefitTranslation.nodes[0].color.item}
                  handleChange={handleChangeSelectOptions}
                  disabled={!(selectOptions && selectOptions["Gender"])}
                  value={selectOptions["Color"]}
                  colors={colorsLyneFit}
                  colorsReverse={colorsReverseLyneFit}
                  formatText={CapitalizeFirstLetter}
                  error={!selectOptions["Color"] && Errors.exist}
                />

                <ProductMaterialInfo
                  data={
                    data.allDirectusLynefitTranslation.nodes[0]
                      .product_material_info
                  }
                />

                <ProductSummaryBottom>
                  <div className="size_drop_down">
                    {!(selectOptions && selectOptions["Gender"]) && (
                      <LayerDisabled />
                    )}
                    <div className="size_drop_down_child">
                      <Select
                        id="pa_taille_classique"
                        name="Size"
                        onChange={handleChangeSelectOptions}
                        value={selectOptions["Size"]}
                        error={!selectOptions["Size"] && Errors.exist}
                        pageName="lynefit"
                      >
                        <option value="">
                          {intl.formatMessage({
                            id: "my_percko_product_size_text",
                          })}
                        </option>
                        {selectOptions["Gender"] &&
                          data.allDirectusLynefitTranslation.nodes[0].size[
                            selectOptions["Gender"]
                          ].map(value => (
                            <option value={value} key={`Size-${value}`}>
                              {value}
                            </option>
                          ))}
                      </Select>
                      {/* <DropIconMobile>
                        <FaAngleDown size={16} />
                      </DropIconMobile>
                      <DropIcon>
                        <FaAngleDown size={16} />
                      </DropIcon> */}
                    </div>
                  </div>
                  {Errors && Errors.exist && (
                    <ErrorBox>
                      {Errors.message.map((item, index) => (
                        <span key={index.toString()}>{item}</span>
                      ))}
                    </ErrorBox>
                  )}
                  <SizeRulerBox>
                    <SizeRulerImage
                      className="lazyload"
                      data-src="https://static.percko.com/uploads/f3c4a6f1-3704-48e1-9668-b19de8889d47.svg"
                    />
                    <GuideSizeDetail
                      onClick={() =>
                        selectOptions &&
                        selectOptions["Gender"] &&
                        setopenSizeGuideModal(!openSizeGuideModal)
                      }
                    >
                      {!(selectOptions && selectOptions["Gender"]) && (
                        <LayerDisabled />
                      )}
                      {
                        data.allDirectusLynefitTranslation.nodes[0]
                          .size_chart_text
                      }
                    </GuideSizeDetail>
                  </SizeRulerBox>
                  {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                    intl.locale === "fr" && (
                      <ProductBottomInfo>
                        <DesktopProductBottomPriceInfo>
                          <ProductPriceWrapper>
                            <ProductPrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductPrice>
                            {/* <ProductDiscountPrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${discountPrice}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductDiscountPrice>
                            <ProductStrikePrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductStrikePrice> */}
                            {/* <ProductPlusDetail
                              onClick={() => setopenModal(!openModal)}
                            >
                              {intl.formatMessage({
                                id: "product_plus_details_text",
                              })}
                            </ProductPlusDetail> */}
                          </ProductPriceWrapper>
                          <ProductAlmaPriceWrapper>
                            <ProductAlmaPrice>
                              ou 3 x{" "}
                              <span>
                                {monthlyPrice(
                                  discountPrice
                                    ? 129
                                    : selectedVariant
                                    ? selectedVariant.price
                                    : product.priceRangeV2.maxVariantPrice
                                        .amount
                                )}
                              </span>
                            </ProductAlmaPrice>
                            <ProductAlmaDetail
                              onClick={() => setopenAlmaModal(!openModal)}
                            >
                              {intl.formatMessage({
                                id: "alma_details_text",
                              })}
                            </ProductAlmaDetail>
                          </ProductAlmaPriceWrapper>
                        </DesktopProductBottomPriceInfo>
                        <ProductBottomCartButtonBox>
                          <ProductQuantity
                            disabled={!selectOptions["Size"]}
                            handleQuantityChange={handleQuantityChange}
                            selectedVariantQuantity={selectedVariantQuantity}
                            mobileDisplay={true}
                          />
                          <div style={{ width: "100%" }}>
                            <ProductAddToCart
                              id="add_to_cart"
                              loading={storeContext.adding ? "adding" : ""}
                              onClick={
                                selectOptions["Gender"]
                                  ? handleAddTocart
                                  : () => {}
                              }
                              disabled={selectOptions["Gender"] ? false : true}
                            >
                              {
                                data.allDirectusLynefitTranslation.nodes[0]
                                  .product_add_to_cart_button
                              }
                            </ProductAddToCart>
                            {selectOptions["Size"] && (
                              <ProductEstimateShippingBox>
                                <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                                <ProductEstimateShippingText>
                                  {cartEstimateDeliveryText(
                                    "LyneFit",
                                    data.allDirectusCommonTranslation.nodes[0]
                                      .months_text,
                                    intl.locale,
                                    selectOptions["Size"],
                                    selectOptions["Gender"],
                                    selectOptions["Color"]
                                  )}
                                </ProductEstimateShippingText>
                              </ProductEstimateShippingBox>
                            )}
                          </div>
                        </ProductBottomCartButtonBox>
                      </ProductBottomInfo>
                    )}
                  {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                    <ProductBottomInfo>
                      <DesktopProductBottomPriceInfo>
                        <ProductPriceWrapper>
                          <ProductPrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                            <ProductPriceInformationSection />
                          </ProductPrice>
                          {/* <ProductDiscountPrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${discountPrice}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                          </ProductDiscountPrice>
                          <ProductStrikePrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                            <ProductPriceInformationSection />
                          </ProductStrikePrice> */}
                        </ProductPriceWrapper>
                        <ProductAlmaPriceWrapper style={{ maxWidth: "215px" }}>
                          <ProductKlarnaSection
                            price={Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}
                          />
                        </ProductAlmaPriceWrapper>
                      </DesktopProductBottomPriceInfo>
                      <ProductBottomCartButtonBox>
                        <ProductQuantity
                          disabled={!selectOptions["Size"]}
                          handleQuantityChange={handleQuantityChange}
                          selectedVariantQuantity={selectedVariantQuantity}
                          mobileDisplay={true}
                        />
                        <div style={{ width: "100%" }}>
                          <ProductAddToCart
                            id="add_to_cart"
                            loading={storeContext.adding ? "adding" : ""}
                            onClick={
                              selectOptions["Gender"]
                                ? handleAddTocart
                                : () => {}
                            }
                            disabled={selectOptions["Gender"] ? false : true}
                          >
                            {
                              data.allDirectusLynefitTranslation.nodes[0]
                                .product_add_to_cart_button
                            }
                          </ProductAddToCart>
                          {selectOptions["Size"] && (
                            <ProductEstimateShippingBox>
                              <ProductEstimateShippingIcon
                                className="lazyload"
                                data-src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg"
                              />
                              <ProductEstimateShippingText>
                                {cartEstimateDeliveryText(
                                  "LyneFit",
                                  data.allDirectusCommonTranslation.nodes[0]
                                    .months_text,
                                  intl.locale,
                                  selectOptions["Size"],
                                  selectOptions["Gender"],
                                  selectOptions["Color"]
                                )}
                              </ProductEstimateShippingText>
                            </ProductEstimateShippingBox>
                          )}
                        </div>
                      </ProductBottomCartButtonBox>
                    </ProductBottomInfo>
                  )}
                  {(process.env.GATSBY_CURRENCY_TYPE === "cad" ||
                    process.env.GATSBY_CURRENCY_TYPE === "usd" ||
                    (process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale != "fr")) && (
                    <ProductInfoBottom>
                      <DesktopPrice>
                        <ProductPriceWrapper>
                          <ProductPrice>
                            {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""}
                            {Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}
                            {process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""}
                          </ProductPrice>
                          <ProductPlusDetail
                            onClick={() => setopenModal(!openModal)}
                          >
                            {intl.formatMessage({
                              id: "product_plus_details_text",
                            })}
                          </ProductPlusDetail>
                        </ProductPriceWrapper>
                      </DesktopPrice>
                      <ProductInfoBottomRight mobileDisplay={true}>
                        <ProductQuantity
                          disabled={!selectOptions["Size"]}
                          handleQuantityChange={handleQuantityChange}
                          selectedVariantQuantity={selectedVariantQuantity}
                          mobileDisplay={true}
                        />
                        <div style={{ width: "100%" }}>
                          <ProductAddToCart
                            id="add_to_cart"
                            loading={storeContext.adding ? "adding" : ""}
                            onClick={
                              selectOptions["Gender"]
                                ? handleAddTocart
                                : () => {}
                            }
                            disabled={selectOptions["Gender"] ? false : true}
                          >
                            {
                              data.allDirectusLynefitTranslation.nodes[0]
                                .product_add_to_cart_button
                            }
                          </ProductAddToCart>
                          {selectOptions["Size"] && (
                            <ProductEstimateShippingBox>
                              <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                              <ProductEstimateShippingText>
                                {cartEstimateDeliveryText(
                                  "LyneFit",
                                  data.allDirectusCommonTranslation.nodes[0]
                                    .months_text,
                                  intl.locale,
                                  selectOptions["Size"],
                                  selectOptions["Gender"],
                                  selectOptions["Color"]
                                )}
                              </ProductEstimateShippingText>
                            </ProductEstimateShippingBox>
                          )}
                        </div>
                      </ProductInfoBottomRight>
                    </ProductInfoBottom>
                  )}
                  <ProductInfoBanner
                    data={
                      data.allDirectusLynefitTranslation.nodes[0]
                        .product_blue_banner_text
                    }
                  />
                </ProductSummaryBottom>
              </div>
            </ProductRightInfoSection>
          </ProductInfoBox>
        </LyneupContainer>

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusCommonTranslation.nodes[0]
            .new_testimonial_section && (
            <TestimonialSection
              data={
                data.allDirectusCommonTranslation.nodes[0]
                  .new_testimonial_section
              }
            />
          )}

        {(intl.locale === "de" || intl.locale === "es") && (
          <ProductBlueBanner
            data={
              data.allDirectusLynefitTranslation.nodes[0]
                .product_blue_banner_text
            }
            background="#9EDAED"
            color="#262626"
          />
        )}

        <ProductResultSection
          borderColor={titleBorderColor}
          data={
            data.allDirectusLynefitTranslation.nodes[0].product_result_section
          }
        />

        {data.allDirectusCommonTranslation.nodes[0].medicale_text && (
          <MedicaleInfoSection
            data={data.allDirectusCommonTranslation.nodes[0].medicale_text}
          />
        )}

        <ProductWhyChooseSection
          borderColor={titleBorderColor}
          titleBgColor="black"
          data={
            data.allDirectusLynefitTranslation.nodes[0]
              .product_why_choose_section
          }
        />

        <ProductExpertSection
          borderColor={titleBorderColor}
          data={
            data.allDirectusLynefitTranslation.nodes[0].product_expert_section
          }
        />

        <ProductSatisfiedClientSection
          borderColor={titleBorderColor}
          data={
            data.allDirectusLynefitTranslation.nodes[0]
              .product_satisfied_client_section
          }
        />

        <ProductVideoSection
          borderColor={titleBorderColor}
          data={
            data.allDirectusLynefitTranslation.nodes[0]
              .product_video_testimonial
          }
        />

        {data.allDirectusReviewTranslation.nodes && (
          <TrustPilotReviewSection
            data={data.allDirectusReviewTranslation.nodes}
            id={"review_section"}
          />
        )}

        {process.env &&
        data.allDirectusLynefitTranslation.nodes[0].gbp_product_faq_section &&
        process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
          <ProductFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLynefitTranslation.nodes[0]
                .gbp_product_faq_section
            }
          />
        ) : (
          <ProductFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLynefitTranslation.nodes[0].product_faq_section
            }
          />
        )}

        {data.allDirectusHomeTranslation.nodes[0].customer_servcie_text && (
          <CustomerService
            text={
              data.allDirectusHomeTranslation.nodes[0].customer_servcie_text
            }
          />
        )}
        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}
        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusHomeTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        customer_servcie_text
      }
    }
    allDirectusLynefitTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        product_title
        product_title_logo_image {
          data {
            url
          }
        }
        product_images_section {
          default_image
          men {
            noir
            bleu
          }
          women {
            noir
            bleu
          }
        }
        product_material_info
        product_add_to_cart_button
        product_blue_banner_text
        product_result_section {
          section_title
          list {
            title
            mobile_title
            text
          }
        }
        product_why_choose_section {
          section_title
          mobile_section_title
          slider {
            image
            title
            text
          }
          text_list {
            image
            text
            mobile_text
          }
        }
        product_expert_section {
          section_title
          mobile_section_title
          list {
            image
            name
            designation
            text
            video_text
            video_url
          }
        }
        product_satisfied_client_section {
          title
          mobile_title
          subtitle
          left_text {
            title
            text
          }
          right_text {
            title
            text
            link
            link_url
          }
        }
        product_video_testimonial {
          testimonials {
            title
            author
            thumb
            video
          }
        }
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        gbp_product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        size {
          Male
          Female
        }
        color {
          item
        }
        choose_gender_text
        gender {
          item
        }
        language
        price_details
        my_custom_size_text
        one_size_system_text
        alma_popup_text
        size_chart_text
        male_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        female_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        male_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        male_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        size_popup_text
        choose_size_suggestion_text
        page_section_list {
          list {
            title
            id
          }
        }
        highlight_section {
          image
          title
          text
        }
        product_information_section {
          title
          list {
            image
            text
          }
        }
        technology_section {
          title
          text
          image
          mobile_image
          button_text
          button_url
        }
        medical_section {
          title
          text
          bottom_text
          button_text
          button_url
          list {
            title
            text
          }
        }
        how_to_use_lynefit_section {
          title
          list {
            image
            text
            second_text
          }
        }
        review_section {
          title
          text
          review_list {
            image
            name
            designation
            company
            city
            text
          }
          media_title
          media_list {
            image
            text
          }
        }
        product_cross_section {
          title
          list {
            image
            title
            logo
            button_text
            button_url
          }
        }
      }
    }
    allDirectusReviewTranslation(
      filter: {
        review: { product: { eq: "lynefit" } }
        language: { eq: $lang }
      }
    ) {
      nodes {
        author_name
        review_text
        place_and_date
        review {
          stars
          name
        }
      }
    }
    allDirectusNewHomePageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        video_section_text {
          title
          mobile_title
          button_text
          button_url
          medical_image
          innovation_image
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        company_title
        company_subtitle
        medicale_text
        medicale_logo {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        months_text {
          month
        }
        group_add_to_cart_title
        group_add_to_cart_save_text
        new_testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
      }
    }
    shopifyProduct(handle: { eq: "lynefit" }) {
      id: storefrontId
      createdAt
      updatedAt
      descriptionHtml
      description
      handle
      productType
      title
      vendor
      publishedAt
      options {
        id
        name
        values
      }
      variants {
        id: storefrontId
        title
        price
        weight
        sku
        image {
          id
          src: originalSrc
          #   altText
        }
        selectedOptions {
          name
          value
        }
        price
      }
      images {
        src: originalSrc
        id
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "lynefit" } } }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(withStoreContext(LyneFit))
