import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const ProductInfoBannerWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 20px;
  display: none;

  @media (max-width: 850px) {
    margin-top: 10px;
  }

  @media (max-width: 850px) {
    margin-top: 20px;
  }
`

const BannerText = styled.div`
  color: #262626;
  font-size: 14px;
  line-height: 18px;
  font-family: "Gotham";
  font-weight: 500;
  font-style: normal;

  ul {
    display: flex;
    margin: 0px;
    list-style: none;
    align-items: flex-start;
    margin: 0px auto;
    flex-direction: column;
  }

  li {
    margin: 0px;
  }
`

export const ProductInfoBanner = ({ intl, data }) => (
  <ProductInfoBannerWrapper>
    {data && <BannerText dangerouslySetInnerHTML={{ __html: data }} />}
  </ProductInfoBannerWrapper>
)

export default injectIntl(ProductInfoBanner)
